<template>
  <div>
    <b-row class="auth-inner m-0 d-flex align-items-center justify-content-center">
      <b-col md="12" class="text-center">
        <!-- Logo -->
        <b-link>
          <b-img-lazy :src="appLogoImage" alt="logo" width="190" class="mt-1" />
        </b-link>
      </b-col>

      <!-- BCard con el formulario para el correo electrónico -->
      <b-col md="5" class="d-flex align-items-center justify-content-center pt-5">
        <b-card class="w-100">
          <b-card-header class="d-flex justify-content-between align-items-center">
            <h2 class="mb-0">Restablecer contraseña</h2>
            <b-link :to="{ name: 'login' }">
              <b-button variant="outline-primary">Iniciar sesión</b-button>
            </b-link>
          </b-card-header>
          <b-card-body>
            <b-form @submit.prevent="handleResetPassword">
              <b-form-group label="Correo electrónico" label-for="emailInput">
                <b-form-input
                  id="emailInput"
                  v-model="email"
                  type="email"
                  placeholder="Introduce tu correo electrónico"
                  required
                />
              </b-form-group>
              <div class="d-flex justify-content-end">
                <b-button variant="primary" type="submit" :disabled="loading">
                  <span v-if="loading">Enviando...</span>
                  <span v-else>Enviar</span>
                </b-button>
              </div>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      id="verification-modal"
      title="Verificación de código"
      hide-footer
      @hide="clearVerificationData"
      centered
    >
      <b-form @submit.prevent="handleCodeVerification">
        <b-form-group label="Código de verificación" label-for="codeInput">
          <b-form-input
            id="codeInput"
            v-model="verificationCode"
            type="text"
            placeholder="Introduce el código que recibiste"
            required
          />
        </b-form-group>
        <div class="d-flex justify-content-end">
          <b-button variant="primary" type="submit" :disabled="loadingVerification">
            <span v-if="loadingVerification">Verificando...</span>
            <span v-else>Verificar</span>
          </b-button>
        </div>
      </b-form>
    </b-modal>

<b-modal
  id="reset-password-modal"
  title="Restablecer contraseña"
  hide-footer
  @hide="clearPasswordData"
  centered
>
  <div v-if="passwordResetSuccess">
    <!-- Mensaje de éxito cuando la contraseña se restablece correctamente -->
    <p class="text-success">Contraseña restablecida correctamente.</p>
    <div class="d-flex justify-content-center">
      <b-button variant="outline-primary" @click="$router.push({ name: 'login' })">
        Iniciar sesión
      </b-button>
    </div>
  </div>
  <div v-else>
    <!-- Formulario de restablecimiento de contraseña -->
    <b-form @submit.prevent="handlePasswordReset">
      <b-form-group label="Nueva contraseña" label-for="passwordInput">
        <b-form-input
          id="passwordInput"
          v-model="newPassword"
          type="password"
          placeholder="Introduce tu nueva contraseña"
          required
        />
      </b-form-group>
      <b-form-group label="Confirmar contraseña" label-for="confirmPasswordInput">
        <b-form-input
          id="confirmPasswordInput"
          v-model="confirmPassword"
          type="password"
          placeholder="Confirma tu nueva contraseña"
          required
        />
      </b-form-group>
      <div class="d-flex justify-content-end">
        <b-button variant="primary" type="submit" :disabled="loadingReset">
          <span v-if="loadingReset">Restableciendo...</span>
          <span v-else>Restablecer</span>
        </b-button>
      </div>
    </b-form>
  </div>
</b-modal>

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BCard,
  BCardHeader,
  BCardBody,
  BImgLazy,
  BModal,
} from 'bootstrap-vue';
import { $themeConfig } from '@themeConfig';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BCard,
    BCardHeader,
    BCardBody,
    BImgLazy,
    BModal,
  },
  data() {
    return {
      email: '',
      appLogoImage: $themeConfig.app.appLogoImage,
      loading: false,
      loadingVerification: false,
      loadingReset: false,
      verificationCode: '',
      newPassword: '',
      confirmPassword: '',
      storedVerificationCode: '', 
      passwordResetSuccess: false,
    };
  },
  methods: {
    async handleResetPassword() {
      this.loading = true;
      try {
        await this.$store.dispatch('user/passwordRecovery', { email: this.email });
        this.$bvModal.show('verification-modal');
        this.$bvToast.toast('Se ha enviado un enlace de restablecimiento de contraseña a tu correo.', {
          title: 'Restablecimiento de contraseña',
          variant: 'success',
          solid: true,
        });
      } catch (error) {
        this.$bvToast.toast(error.response?.data?.message || 'Ha ocurrido un error al restablecer la contraseña.', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        });
      } finally {
        this.loading = false;
      }
    },

    async handleCodeVerification() {
      this.loadingVerification = true;
      try {
        await this.$store.dispatch('user/validateCode', {
          email: this.email,
          code: this.verificationCode,
        });

        this.storedVerificationCode = this.verificationCode;
        this.verificationCode = '';
        this.$bvModal.hide('verification-modal');
        this.$bvModal.show('reset-password-modal');

        this.$bvToast.toast('Código verificado correctamente.', {
          title: 'Verificación exitosa',
          variant: 'success',
          solid: true,
        });
      } catch (error) {
        // Manejo de errores
        this.$bvToast.toast(error.response?.data?.message || 'Código incorrecto o expirado.', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        });
      } finally {
        this.loadingVerification = false;
      }
    },

    async handlePasswordReset() {
      this.loadingReset = true;
      if (this.newPassword !== this.confirmPassword) {
        this.$bvToast.toast('Las contraseñas no coinciden.', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        });
        this.loadingReset = false;
        return;
      }

      try {
        await this.$store.dispatch('user/resetPassword', {
          email: this.email,
          code: this.storedVerificationCode,
          password: this.newPassword,
        });

        // Indicar que la contraseña se restableció correctamente
        this.passwordResetSuccess = true;
      } catch (error) {
        this.$bvToast.toast(error.response?.data?.message || 'Error al restablecer la contraseña.', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        });
      } finally {
        this.loadingReset = false;
      }
    },

    clearVerificationData() {
      this.verificationCode = '';
    },
    clearPasswordData() {
      this.newPassword = '';
      this.confirmPassword = '';
      this.storedVerificationCode = '';
      this.passwordResetSuccess = false; // Restablecer el estado para el próximo intento
    },
  },
};
</script>

<style scoped>
.text-center {
  text-align: center;
}
.mt-1 {
  margin-top: 1rem;
}
.pt-5 {
  padding-top: 3rem;
}
</style>
